import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import '../styles/RSVP.css';

function RSVP() {
  const [mainGuest, setMainGuest] = useState({
    name: '',
    email: '',
    sponsor: 'NA',
    food: '',
    accommodations: '',
    dietaryRestrictions: '',
  });
  const [attending, setAttending] = useState(null);
  const [guestCount, setGuestCount] = useState(1);
  const [additionalGuests, setAdditionalGuests] = useState([]);

  const updateAdditionalGuests = useCallback(() => {
    const newCount = Math.max(0, guestCount - 1);
    setAdditionalGuests(prevGuests => {
      if (newCount > prevGuests.length) {
        const newGuests = [...prevGuests];
        for (let i = prevGuests.length; i < newCount; i++) {
          newGuests.push({ name: '', email: '', attending: attending, sponsor: mainGuest.name, food: '', accommodations: '', dietaryRestrictions: '' });
        }
        return newGuests;
      } else if (newCount < prevGuests.length) {
        return prevGuests.slice(0, newCount);
      }
      return prevGuests;
    });
  }, [guestCount, mainGuest.name, attending]);

  useEffect(() => {
    updateAdditionalGuests();
  }, [updateAdditionalGuests]);

  const handleMainGuestChange = (field) => (e) => {
    setMainGuest({ ...mainGuest, [field]: e.target.value });
  };

  const handleAdditionalGuestChange = (index, field) => (e) => {
    const newGuests = [...additionalGuests];
    newGuests[index] = { ...newGuests[index], [field]: e.target.value };
    setAdditionalGuests(newGuests);
  };

  const handleAttendingChange = (value) => {
    setAttending(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;
      const authHeader = `Basic ${btoa(`${username}:${password}`)}`;

      // Use environment variable to determine the base URL
      const baseURL = process.env.NODE_ENV === 'production'
        ? 'http://jimamy2025.com:8091/api/v1'
        : 'http://localhost:8090/api/v1';

      const axiosInstance = axios.create({
        baseURL: baseURL,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': authHeader
        },
        timeout: 5000
      });

      // Submit main guest RSVP
      const mainGuestData = {
        rsvp: {
          name: mainGuest.name,
          email: mainGuest.email || '', // Send empty string if email is not provided
          attending: attending,
          sponsor: mainGuest.sponsor,
          food: mainGuest.food,
          accommodations: mainGuest.accommodations,
          dietary_restrictions: mainGuest.dietaryRestrictions
        }
      };

      console.log('Submitting main guest RSVP:', mainGuestData);
      const mainGuestResponse = await axiosInstance.post('/rsvp', mainGuestData);
      console.log('Main guest RSVP response:', mainGuestResponse);

      if (mainGuestResponse.status !== 200 && mainGuestResponse.status !== 201) {
        throw new Error(`Failed to submit main guest RSVP. Status: ${mainGuestResponse.status}`);
      }

      // Submit additional guests RSVPs
      for (const guest of additionalGuests) {
        const guestData = {
          rsvp: {
            name: guest.name,
            email: guest.email || '', // Send empty string if email is not provided
            attending: attending,
            sponsor: mainGuest.name,
            food: guest.food,
            accommodations: guest.accommodations,
            dietary_restrictions: guest.dietaryRestrictions
          }
        };

        console.log(`Submitting additional guest RSVP for ${guest.name}:`, guestData);
        const guestResponse = await axiosInstance.post('/rsvp', guestData);
        console.log(`Additional guest RSVP response for ${guest.name}:`, guestResponse);

        if (guestResponse.status !== 200 && guestResponse.status !== 201) {
          throw new Error(`Failed to submit additional guest RSVP for ${guest.name}. Status: ${guestResponse.status}`);
        }
      }

      console.log('All RSVPs submitted successfully');
      alert('All RSVPs submitted successfully!');
      // Reset form
      setMainGuest({ name: '', email: '', sponsor: 'NA', food: '', accommodations: '', dietaryRestrictions: '' });
      setAttending(true);
      setGuestCount(1);
      setAdditionalGuests([]);
    } catch (error) {
      console.error('Error submitting RSVP:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        } else if (error.request) {
          console.error('No response received. Request details:', error.request);
        } else {
          console.error('Error setting up request:', error.message);
        }
        console.error('Error config:', error.config);
      } else {
        console.error('Non-Axios error:', error);
      }
      alert(`There was an error submitting your RSVP: ${error.message}. Please check the console for more details.`);
    }
  };

  return (
    <div className="rsvp">
      <div className="deadline-banner">
        Please RSVP by February 1st, 2025
      </div>
      <h2>RSVP</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Your Name</label>
          <input
            id="name"
            type="text"
            value={mainGuest.name}
            onChange={handleMainGuestChange('name')}
            required
            maxLength={100}
          />
          <small>To make changes, resubmit with the same name and email and your rsvp will be updated.</small>
        </div>
        <div className="form-group">
          <label htmlFor="email">Your Email</label>
          <input
            id="email"
            type="email"
            value={mainGuest.email}
            onChange={handleMainGuestChange('email')}
            maxLength={100}
          />
          <small>Confirmation is sent to email.</small>
        </div>
        <div className="form-group attending-group">
          <label>Attending</label>
          <div className="attending-checkboxes">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={attending === true}
                onChange={() => handleAttendingChange(true)}
              />
              Yes
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={attending === false}
                onChange={() => handleAttendingChange(false)}
              />
              No
            </label>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="food">Food Preference</label>
          <select
            id="food"
            value={mainGuest.food}
            onChange={handleMainGuestChange('food')}
            required
          >
            <option value="">Select food preference</option>
            <option value="beef">Beef</option>
            <option value="chicken">Chicken</option>
            <option value="fish">Fish</option>
            <option value="vegetarian_napolean">Vegetarian Napoleon</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="dietaryRestrictions">Dietary Restrictions</label>
          <input
            id="dietaryRestrictions"
            type="text"
            value={mainGuest.dietaryRestrictions}
            onChange={handleMainGuestChange('dietaryRestrictions')}
            placeholder="e.g. Gluten-free, Vegan, Allergies"
            maxLength={5000}
          />
        </div>
        <div className="form-group">
          <label htmlFor="accommodations">Accommodation Needs</label>
          <input
            id="accommodations"
            type="text"
            value={mainGuest.accommodations}
            onChange={handleMainGuestChange('accommodations')}
            placeholder="e.g. Would like to share Airbnb with other guests, etc."
            maxLength={5000}
          />
        </div>
        <div className="form-group">
          <label htmlFor="guestCount">Number of Guests (including yourself)</label>
          <div className="guest-count-input-container">
            <button
              type="button"
              onClick={() => setGuestCount(prev => Math.max(1, prev - 1))}
              className="guest-count-button"
            >
              -
            </button>
            <input
              id="guestCount"
              type="number"
              value={guestCount}
              onChange={(e) => setGuestCount(Math.max(1, parseInt(e.target.value) || 1))}
              min="1"
              required
              className="guest-count-input"
            />
            <button
              type="button"
              onClick={() => setGuestCount(prev => prev + 1)}
              className="guest-count-button"
            >
              +
            </button>
          </div>
        </div>

        {additionalGuests.map((guest, index) => (
          <div key={index} className="additional-guest">
            <h3>Additional Guest {index + 1}</h3>
            <div className="form-group">
              <label htmlFor={`guestName${index}`}>Guest Name</label>
              <input
                id={`guestName${index}`}
                type="text"
                value={guest.name}
                onChange={handleAdditionalGuestChange(index, 'name')}
                required
                maxLength={100}
              />
            </div>
            <div className="form-group">
              <label htmlFor={`guestEmail${index}`}>Guest Email</label>
              <input
                id={`guestEmail${index}`}
                type="email"
                value={guest.email}
                onChange={handleAdditionalGuestChange(index, 'email')}
                maxLength={100}
              />
              <small>Email is optional. Used for confirmation and to allow for changes by resubmitting.</small>
            </div>
            <div className="form-group">
              <label htmlFor={`guestFood${index}`}>Guest Food Preference</label>
              <select
                id={`guestFood${index}`}
                value={guest.food}
                onChange={handleAdditionalGuestChange(index, 'food')}
                required
              >
                <option value="">Select food preference</option>
                <option value="beef">Beef</option>
                <option value="chicken">Chicken</option>
                <option value="fish">Fish</option>
                <option value="vegetarian_napolean">Vegetarian Napoleon</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor={`guestDietaryRestrictions${index}`}>Guest Dietary Restrictions</label>
              <input
                id={`guestDietaryRestrictions${index}`}
                type="text"
                value={guest.dietaryRestrictions}
                onChange={handleAdditionalGuestChange(index, 'dietaryRestrictions')}
                placeholder="e.g. Gluten-free, Vegan, Allergies"
                maxLength={5000}
              />
            </div>
            <div className="form-group">
              <label htmlFor={`guestAccommodation${index}`}>Guest Accommodation Needs</label>
              <input
                id={`guestAccommodation${index}`}
                type="text"
                value={guest.accommodations}
                onChange={handleAdditionalGuestChange(index, 'accommodations')}
                placeholder="e.g. Would like to share Airbnb with other guests, etc."
                maxLength={5000}
              />
            </div>
          </div>
        ))}
        <button type="submit" className="submit-button">Submit RSVP</button>
      </form>
    </div>
  );
}

export default RSVP;