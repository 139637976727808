import React from 'react';
import '../styles/Schedule.css';

const Schedule = () => {
  return (
    <div className="schedule">
      <h2>Wedding Day Schedule</h2>
      <h3>March 9th, 2025</h3>

      <div className="info-box">
        <div>All Guests: 5:00 PM - 10:00 PM</div>
        <div>Groomsmen + Bridesmaids: 1:00 PM - 10:00 PM</div>
      </div>

      <ul className="schedule-list">
        <li className="schedule-item">
          <div className="schedule-item-header">1:00 PM - 2:30 PM | Outdoor Photos</div>
          <ul className="schedule-sublist">
            <li>Family Photos</li>
            <li>Wedding Party Photos (+Groomsmen +Bridesmaids)</li>
            <li>Couple Photos</li>
          </ul>
        </li>

        <li className="schedule-item">
          <div className="schedule-item-header">3:00 PM - 4:30 PM | Indoor Photos</div>
          <ul className="schedule-sublist">
            <li>Family Photos</li>
            <li>Wedding Party Photos (+Groomsmen +Bridesmaids)</li>
            <li>Couple Photos</li>
          </ul>
        </li>

        <li className="schedule-item">
          <div className="schedule-item-header">5:00 PM - 6:00 PM | Ceremony</div>
        </li>

        <li className="schedule-item">
          <div className="schedule-item-header">6:00 PM - 6:45 PM | Reception Transition</div>
          <ul className="schedule-sublist">
            <li>Transition downstairs to reception area</li>
            <li>Drinks and appetizers</li>
            <li>Guest take note of table arrangements</li>
          </ul>
        </li>

        <li className="schedule-item">
          <div className="schedule-item-header">6:45 PM - 7:00 PM | First Dance</div>
          <div className="schedule-item-content">DJ will announce</div>
        </li>

        <li className="schedule-item">
          <div className="schedule-item-header">7:00 PM - 8:00 PM | Dinner</div>
          <ul className="schedule-sublist">
            <li>Guests are seated at table during first dance</li>
            <li>Dinner</li>
            <li>Cake cutting</li>
          </ul>
        </li>

        <li className="schedule-item">
          <div className="schedule-item-header">8:00 PM - 8:10 PM | Speeches</div>
          <div className="schedule-item-content">Maid of Honor and Best Man speeches</div>
        </li>

        <li className="schedule-item">
          <div className="schedule-item-header">8:10 PM - 8:20 PM | Photo Slideshow</div>
        </li>

        <li className="schedule-item">
          <div className="schedule-item-header">8:20 PM - 8:30 PM | Parent Speech</div>
        </li>

        <li className="schedule-item">
          <div className="schedule-item-header">8:30 PM - 8:35 PM | Special Dances</div>
          <div className="schedule-item-content">Father-Daughter and Mother-Son dances (concurrent)</div>
        </li>

        <li className="schedule-item">
          <div className="schedule-item-header">8:35 PM - 9:05 PM | Waltz</div>
          <div className="schedule-item-content">Everyone dances</div>
        </li>

        <li className="schedule-item">
          <div className="schedule-item-header">9:05 PM - 9:35 PM | Pop Songs</div>
          <div className="schedule-item-content">Everyone dances</div>
        </li>

        <li className="schedule-item">
          <div className="schedule-item-header">9:35 PM - 9:50 PM | Wedding Games</div>
          <div className="schedule-item-content">Bouquet Toss or Shoe Game, etc., TBD </div>
        </li>

        <li className="schedule-item">
          <div className="schedule-item-header">9:50 PM - 10:00 PM | Exit</div>
        </li>
      </ul>
    </div>
  );
};

export default Schedule;